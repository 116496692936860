@font-face {
    font-family: 'Hellix';
    src: url(./Font/Hellix-Regular.ttf);
}

body {
    font-family: Hellix;
}

/**************  STYLES OF NAVBAR COMPONENT  *********************/

#containerNavBar {
    height: 80px;
    padding: 10px;
    background-color: #FFFF;
    border-bottom: 1px solid #F0EFFB;
    box-shadow: 0.1px 0.1px 0.1px #999;
}

#containerButtons1 {
    border-right: 1px solid #E0E4F1;
}

#containerButtons2 {
    padding: 5px 9px;
}

#iconLogOut {
    padding-right: 10px;
}

.stylebutton1 {
    width: 120px;
    height: 40px;
    background-color: white;
    border: 1.2px solid #E0E4F1;
    border-radius: 55px;
    margin: 0px 12px;
    font-family: Hellix;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #394055;
}

.stylebutton2 {
    width: 130px;
    height: 30px;
    padding: 5px 9px;
    background-color: #FFE7E3;
    border: none;
    border-radius: 8px;
    margin: 0px 12px;
    font-family: Hellix;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #F05D44;
}

/*************  STYLES OF LOGIN PAGE  **************************/
#containerForm {
    margin-top: 80px;
    width: 33%;
    margin-left: 33%;
}

#footerLogin {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 25px;
    background-color: #2E5CFF;
}

#spanError {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
}

/******************  FORM  ******************************/

#tittleForm {
    font-family: Hellix;
    font-weight: 700;
    font-size: 48px;
    line-height: 125%;
    text-align: center;
    color: #394055;
}

#subTittleForm {
    font-family: Hellix;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    color: #747B92;
}

#inputEmail {
    margin-bottom: 20px;
}

#inputPassword {
    margin-bottom: 40px;
}

.containerInput {
    width: 100%;
}

.containerInput .form-control {
    padding-left: 45px;
    font-family: Hellix;
    font-size: 20px;
    font-weight: 400;
}

.containerInput .form-control-feedback {
    padding-left: 18px;
    position: absolute;
    z-index: 2;
    display: block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    pointer-events: none;
    font-size: 20px;
    margin-top: 11px;
}

#buttonLogin {
    border: none;
    width: 110px;
    height: 40px;
    background-color: #2E5CFF;
    border-radius: 54px;
    font-family: Hellix;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
}

/****************************  HOME  ******************************/

#containerHome {
    overflow: hidden;
    height: calc(100vh - 80px);
}

#containerHomeLeft {
    width: 40%;
    height: 100%;
}

#containerHomeInLeft {
    width: 456px;
    height: 533px;
    background-color: #FFFFFF;
    border: 1.5px solid #F0EFFB;
    box-shadow: 0px 12px 24px rgba(17, 40,  121, .07);
    border-radius: 24px;
    padding: 70px 60px 60px 70px;
}

#containerHomeRight {
    width: 60%;
    height: 100%;
    background-color: #F9FAFF;
}

#tittleHomeLeft {
    font-family: Hellix;
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
}

#subTittleHomeLeft {
    font-family: Hellix;
    font-weight: 400;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
}

#buttonHomeLeftYellow {
    width: 80%;
    height: 40px;
    background-color: #F7CB2F;
    border: none;
    border-radius: 55px;
    font-family: Hellix;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #394055;
    margin-bottom: 0px;
}

#buttonHomeLeftBlue {
    width: 80%;
    height: 40px;
    background-color: #2E5CFF;
    border: none;
    border-radius: 55px;
    font-family: Hellix;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 12px;
}

#labelInputHomeBlue {
    width: 80%;
    height: 40px;
    background-color: #2E5CFF;
    border: none;
    border-radius: 55px;
    font-family: Hellix;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #FFFFFF;
    padding-top: 5px;
    margin-bottom: 12px;
}

#inputHomeIdZoho {
    margin-bottom: 20px;
    background: #FFFFFF;
    margin-Top: 32px;
    border: 1px solid #E0E4F1;
    border-radius: 12px;
}

#inputHomeAlias {
    margin-bottom: 20px;
    height: 100px;
    background: "#FFFFFF";
    border: "1px solid #E0E4F1";
    border-radius: "12px";
    text-transform: uppercase;
}

.containerInput .form-control-2feedback {
    padding-left: 18px;
    position: absolute;
    z-index: 2;
    display: block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    pointer-events: none;
    font-size: 20px;
    margin-top: 10px;
}

/**********************  DATOS MANUALES *********************/

#containerButtons {
    padding-right: 30px;
    padding-left: 30px;
    padding: 12px 4%;
}

#buttonManualDataReturnPage {
    width: 100px;
    height: 40px;
    padding: 10px 15px;
    border: 2px solid #EFF2FD;
    border-radius: 90px;
    font-family: Hellix;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #394055;
}

#buttonManualDataSearch {
    width: 100px;
    height: 40px;
    border: none;
    border-radius: 54px;
    background-color: #2E5CFF;
    font-family: Hellix;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
}

#containerBodyManualData {
    min-height: 80vh;
    background-color: #F9FAFF;
    padding-bottom: 50px;
}

#containerTittleManualData {
    padding-top: 30px;
    margin-left: 7.5%;

}

#tittle1ManualData {
    font-family: Hellix;
    font-weight: 600;
    font-size: 28px;
    line-height: 100%;
    color: #4F566B;
}

#tittle2ManualData {
    font-family: Hellix;
    font-weight: 600;
    font-size: 28px;
    line-height: 100%;
    color: #2E5CFF;
    margin-left: 5px;
}

#containerInputsManualData {
    width: 85%;
    margin-left: 7.5%;
    padding-top: 1%;
    padding-bottom: 1%;
    background-color: #FFFFFF;
    border: 1.5px solid #F0EFFB;
    box-shadow: 0px 3px 10px rgba(17, 40, 121, .06);
    border-radius: 25px;
}

.containerInputManualData {
    margin-top: 10px;
    margin-bottom: 10px;
}

.containerInputManualData .form-control {
    margin-left: 2%;
    width: 92%;
    padding-left: 45px;
    font-family: Hellix;
    font-size: 20px;
    font-weight: 400;
}

.containerInputManualData .form-control-ManualData {
    padding-left: 40px;
    position: absolute;
    z-index: 2;
    display: block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    pointer-events: none;
    font-size: 20px;
    margin-top: 11px;
}

#buttonTrashManualData {
    font-size: 23px;
}

#iconPlusManualData {
    margin-right: 10px;
    font-size: 20px;
    font-weight: 600;
}

#buttonAddRow {
    margin-left: 25px;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #F7CB2F;
    width: 160px;
    height: 40px;
    box-shadow: 0px 3px 10px rgba(17, 40, 121, .12);
    border: none;
    border-radius: 50px;
    font-family: Hellix;
    font-size: 20px;
    line-height: 14px;
    font-weight: 600;
    color: #394055;
}

/******************************* POPUP *****************************/
#modalStyle {
    display: block;
    background-color: rgba(0, 0, 0, .8);
}

#modalStyleAddItem {
    display: block;
    background-color: rgba(46,92,255,.75);
}

#tittlePopUpDtats {
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    color: #394055;
}

#pTotalRowPopUpStats {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #4F566B;
}

#pTotalCotizadosPopUpStats {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #0D6EFD;
}

#pTotalPendientesPopUpStats {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #CEA928;
}

#buttonCancelPopUpStats {
    background-color: #EFF2FD;
    border: none;
    border-radius: 30px;
    width: 120px;
    height: 40px;
    padding: 7px 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #394055;
}

#buttonCreatePopUpStats {
    background-color: #0D6EFD;
    border-radius: 30px;
    width: 80px;
    height: 40px;
    padding: 7px 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
}

/************************************  RESULTS  ************************************************/
.button-result {
    background-color: #fff;
    border: solid 1px#0D6EFD;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    transition: 0.5s;
}

.button-result-normal:hover {
    background-color: #0D6EFD;
    border: solid 1px#0D6EFD;
}


.button-result-normal:hover i {
    color: #fff;
    transition: 0.5;
}

.button-result-copied {
    background-color: #00A71B;
    border: solid 1px #00A71B;
}

.button-result-copied:hover {
    background-color: #00A71B;
    border: solid 1px #00A71B;
}

.icon-checked{
    color: #fff !important;
}

.button-result:first-child {
    margin-right: 10px;
}

.button-result i {
    color: #0D6EFD;
    font-size: 20px;
    transition: 0.5;
}

.background-copied {
    position: absolute;
    left: 43%;
    align-items: center;
    z-index: 3;
    background: #000;
    opacity: 0.8;
    border-radius: 10px;
    padding: 20px 20px 10px 20px;
}

.background-copied p {
    color: #fff;
}

#buttonReturnResults {
    height: 40px;
    background-color: #FFFFFF;
    border: 2px solid #EFF2FD;
    border-radius: 55px;
    color: #394055;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
}

#buttonSaveResults {
    margin: 6px;
    width: 110px;
    height: 40px;
    background-color: #EFF2FD;
    border: none;
    border-radius: 55px;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #394055;
}
 
#buttonPublishResults {
    margin: 6px;
    width: 110px;
    height: 40px;
    background-color: #2E5CFF;
    border: none;
    border-radius: 55px;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
}

#containerStatsResults {
    width: 100%;
    background-color: #394055;
    padding: 12px 4%;

}

#pAlias {
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    margin-bottom: 0px;
    line-height: 25px;
}

#pIdCliente {
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 25px;
    margin-bottom: 0px;
}

#pStatsResults {
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    color: #F7CB2F;
    text-align: right;
    margin-bottom: 0px;
}

#pTotalAprox {
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
    text-align: right;
    margin-bottom: 0px;
}

#buttonSaveFloat {
    width: 3%;
    height: 6%;
    position: fixed;
    bottom: 5%;
    right: 2%;
    background-color: #FFFFFF;
    border: 1.2px solid #828384;
    border-radius: 80px;
}

#buttonSaveFloat i {
    font-size: 25px;
}

#inputCostOfDelivery {
    margin-left: 10px;
    margin-bottom: 20px;
    background: #FFFFFF;
    margin-Top: 32px;
    border: 1px solid #E0E4F1;
    border-radius: 12px;
    padding-left: 15px;
    height: 32px;
}

#containerInputCostDelivery {
    background-color: #F9FAFF;
    padding-left: 7.5%;
    width: 100%;
}

/**************************************  Table Results  **************************/
td {
    vertical-align: middle;
  }

th {
    vertical-align: middle;
  }

.tableBody{
    border-color: transparent;
    text-transform: uppercase;
}

/**filas impares**/
.tableBody tr:nth-child(even) {
    background-color: #EFF2FD;
}
.tableBody tr:nth-child(even) td {
    background-color: transparent;
}

/**filas pares**/
.tableBody tr:nth-child(odd) {
    background-color: #F9FAFF;
}

.tableBody td {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
}

.tableBody th .btn {
    display: block;
	margin:auto;
}

.tableBody .selectedSku {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: white;
}

/* top-left border-radius */
table tr:first-child th:first-child {
    border-top-left-radius: 18px;
  }
  
  /* top-right border-radius */
  table tr:first-child th:last-child {
    border-top-right-radius: 18px;
  }
  
  /* bottom-left border-radius */
  table tr:last-child td:first-child {
    border-bottom-left-radius: 18px;
  }
  
  /* bottom-right border-radius */
  table tr:last-child td:last-child {
    border-bottom-right-radius: 18px;
  }


#containerTablesResults {
    background-color: #F9FAFF;
}

.containerTables {
    width: 85%;
    margin-left: 7.5%;
}

#tittleTableResults {
    font-weight: 600;
    font-size: 28px;
    color: #2E5CFF;
}

#containerTableResult {
    box-shadow: 0px 2px 4px rgba(46, 92, 255, .24);
    border-radius: 18px;
}

.headTableResult {
    background-color: #2E5CFF;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
}

.headTableResult th{
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 20px;
}


.headTableResult td{
    padding-top: 24px;
    padding-bottom: 24px;
}

#columnNumber {
    width: 5%;
    font-weight: 700;
    font-size: 16px;
}

#columnItem {
    width: 74%;
    font-weight: 700;
    font-size: 16px;
}

#columnQuantity {
    width: 12%;
    font-weight: 700;
    font-size: 16px;
    font-weight: 100%;
}

#columnSkus {
    width: 8%;
    font-weight: 700;
    font-size: 16px;
    font-weight: 100%;
}

#columnPoints {
    width: 1%;
}

.buttonPoints {
    box-shadow: none;
}

#textNumber {
    font-weight: 700;
    font-size: 24px;
    color: #394055;
}

#textItem {
    font-weight: 900;
    font-size: 16px;
    color: #394055;
}

#textLengthResults {
    font-weight: 700;
    font-size: 20px;
    color: #394055;
}

#inputNumberRequest {
    width: 90px;
    height: 40px;
    background-color: #F9FAFF;
    border: 1.6px solid #E6E8F2;
    box-shadow: 0px 1px 4px rgba(9, 24, 64, .08);
    border-radius: 10px;
    font-weight: 600;
    font-size: 18px;
    color: #4F566B;
    text-align: center;
}

#points button{
    font-size: 20px;
}

.opstionsResult {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #394055;
}

#button-file{
    border: none;
    background-color: transparent;
}

.icon-file-color{
    color:#A2A9C1;
    font-size: 32.5px;
}

.descriptionSku {
    font-weight: 400;
    color: gray;
    font-size: 16px;
    text-decoration: underline;
    line-height: 23px;
    margin-bottom: 0px;
}

.brandSku {
    font-weight: 700;
    color: #4F566B;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 0px;
}

.priceSku {
    font-weight: 700;
    color: #2E5CFF;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 0px;
}

.supplierSku {
    font-weight: 700;
    color: #4F566B;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 0px;
}

.btn-star {
    font-size: 25px;
    text-align: center;
}

/*********************************  Table no result *****************************/

#tittleTableNotResults {
    font-weight: 600;
    font-size: 28px;
    line-height: 100%;
    color: #CEA928;
}

#containerTableNoResult {
    box-shadow: 0px 3px 10px rgba(17, 40, 121, 0.06);
    border-radius: 18px;
}

#headNoResults {
    background-color: #F7CB2F;
}


#headNoResults th{
    padding-top: 24px;
    padding-bottom: 24px;
}

#headNoResults td{
    padding-top: 24px;
    padding-bottom: 24px;
}

#footerNoResults {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #F7CB2F;
}


#buttonAddRowNotResults icon {
    font-size: 20px;
}

#containerPoints {
    text-align: right;
}

#pHashtag {
    width: 5%;
    font-weight: 700;
    font-size: 16px;
    color: #394055;
}

.pPositionNotResults {
    font-weight: 700 !important;
    font-size: 24px;
    color: #394055;
}

.pNameItemNotResults {
    font-weight: 700;
    font-size: 16px;
    color: #394055;
}

#pNameItemNotHeadResults {
    font-weight: 700;
    font-size: 16px;
    color: #394055;
    padding-left: 20px;
}

#buttonPointsNotResults {
    width: 265px;
    height: 40px;
    background-color: #FFFFFF;
    border: none;
    border-radius: 50PX;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #394055;
}

#iconPlusNotResults {
    font-size: 14px;
    padding-right: 20px;
}

/*************************************  Table discarted  ************************/


#containerTableDiscarted {
    box-shadow: 0px 3px 10px rgba(17, 40, 121, 0.06);
    border-radius: 18px;
}

#tittleDiscarted {
    font-weight: 700;
    font-size: 28px;
    color: #F05D44;
}

#subTittleDiscarted {
    font-weight: 700;
    font-size: 14px;
    color: #394055;
}

#headTableDiscarted {
    background-color: #F05D44;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 16px;
}


#headTableDiscarted th{
    padding-top: 24px;
    padding-bottom: 24px;
}

#headTableDiscarted td{
    padding-top: 24px;
    padding-bottom: 24px;
}

#positionDiscarted {
    width: 5%;
    font-weight: 700;
    font-size: 24px;
    color: #394055;
    text-align: center;
}

#itemNameDiscarted {
    width: 70%;
    font-weight: 700;
    font-size: 16px;
    color: #394055;
    padding-left: 20px;
}

#headTableDiscarted th {
    padding-left: 20px;
}

#buttonToggleDiscarted {
    width: 225px;
    height: 40px;
    background-color: #F9FAFF;
    border: 1.6px solid #E6E8F2;
    box-shadow: 0px, 1px 4px rgba(9, 24, 64, .08);
    border-radius: 10px;
}

/************************************  POPUP ADDITEM  *************************************************/
.modal-dialogAddItem {
    width: 77%;
    margin-left: 11.5%;
}

.modal-contentAddItem {
    min-height: 600px;
    background-color: white;
}

.modal-dialog {
    max-width: 50%;
    border-style: none;
}

#modal-header {
    padding: 0;
}

#containerFirstButtons {
    padding: 16px;
}

.headerAddItem {
    background-color: #1F232F;
    border-radius: 0;
    height: 15%;
    flex-wrap: nowrap;
}

#tittleAddItem {
    font-weight: 700;
    font-size: 20px;
    line-height: 50%;
    color: white;
    padding-top: 5px;
}

#subTittleAddItem {
    font-weight: 400;
    font-size: 20px;
    line-height: 50%;
    color: white;
}

#buttonInfoAddItem {
    background-color: white;
    border-color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #394055;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    display: inline-block;
}

#buttonInfoAddItem .tooltiptext {
    /*visibility: hidden;*/
    width: 150px;
    background-color: white;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 5px 15px;

    /* Position the tooltip content */
    position: absolute;
    z-index: 1;
    margin-left: -200px;

    /* Fade in the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

#buttonInfoAddItem:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

#buttonCloseAddItem {
    width: 75px;
    height: 40px;
    border-radius: 90px;
    border: 1.6px solid #8990A8;
    background-color: rgba(255,255,255,0);
    color: #8990A8;
}

#containerCloseAddItem {
    padding-top: 15px;
}

#containerYellowAddItem {
    width: 100%;
    background-color: #F7CB2F;
    padding: 15px;
    height: 10%;
    margin-bottom: 20px;
    border-radius: 10px;
}

#containerGreenAddItem {
    width: 100%;
    background-color: #00A71B;
    padding: 15px;
    height: 10%;
    margin-bottom: 20px;
    border-radius: 10px;
}

#tittleScraping {
    color: white;
    font-size: 16px;
    font-weight: 700;
}

#inputScraping {
    height: 50px;
    border-radius: 10px;
    border-color: white;
    padding-left: 10px;
    color: #4C5366;
}

#buttonSearchScraping {
    background-color: #2E5CFF;
    border-color: #2E5CFF;
    color: white;
    padding: 10px 15px;
    width: 120px;
    height: 50px;
    border-radius: 275px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
}

.containerFormAddItem {
    background-color: #EFF2FD;
    padding: 20px;
}

.tittleFormAddItem {
    color: #394055;
    font-weight: 700;
    font-size: 20px;
}

.styleInputAddItem {
    border-style: none;
    border-radius: 12px;
    background-color: white;
    padding: 10px;
    margin: 10px 5px;
}

#saveAddItem {
    background-color: #2E5CFF;
    border-radius: 300px;
    width: 200px;
    height: 50px;
    font-weight: 700;
    font-size: 16px;
    color: white;
}

#tituloResultScraping {
    color: white;
    font-size: 22px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 0;
}

#subtituloResultScraping {
    color: white;
    font-size: 18px;
    text-align: center;
    font-weight: 400;
}
/******Animation******/

.sk-cube-grid {
    width: 120px;
    height: 50px;
  }
  
  .sk-cube-grid .sk-cube {
    width: 33%;
    height: 33%;
    background-color: #2E5CFF;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
            animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; 
  }
  .sk-cube-grid .sk-cube1 {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s; }
  .sk-cube-grid .sk-cube2 {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s; }
  .sk-cube-grid .sk-cube3 {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s; }
  .sk-cube-grid .sk-cube4 {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s; }
  .sk-cube-grid .sk-cube5 {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s; }
  .sk-cube-grid .sk-cube6 {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s; }
  .sk-cube-grid .sk-cube7 {
    -webkit-animation-delay: 0s;
            animation-delay: 0s; }
  .sk-cube-grid .sk-cube8 {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s; }
  .sk-cube-grid .sk-cube9 {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s; }
  
  @-webkit-keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
      -webkit-transform: scale3D(1, 1, 1);
              transform: scale3D(1, 1, 1);
    } 35% {
      -webkit-transform: scale3D(0, 0, 1);
              transform: scale3D(0, 0, 1); 
    }
  }
  
  @keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
      -webkit-transform: scale3D(1, 1, 1);
              transform: scale3D(1, 1, 1);
    } 35% {
      -webkit-transform: scale3D(0, 0, 1);
              transform: scale3D(0, 0, 1);
    } 
  }
  

/*********************************  POP UP SAVE CHANGES ************************************************/
#buttonContinueEdit {
    width: 170px;
    height: 40px;
    background-color: #EFF2FD;
    border: none;
    border-radius: 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #394055;
}

#buttonCloseQuote {
    width: 150px;
    height: 40px;
    background-color: #0D6EFD;
    border: none;
    border-radius: 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
    margin-left: 10px;
}

#buttonCloseQuoteRed {
    width: 150px;
    height: 40px;
    background-color: red;
    border: none;
    border-radius: 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
    margin-left: 10px;
}

/*********************************  REVIEWQUOTES  ********************************************************/
#containerButtonsReview {
    height: 75px;
    background-color: #FFFFFF;
    border-bottom: 1px solid #F0EFFB;
    padding: 15px 50px;
}

#buttonReturnHome {
    height: 40px;
    width: 90px;
    background-color: #FFFFFF;
    border: 2px solid #EFF2FD;
    border-radius: 55px;
    color: #394055;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
}

#inputAliasToSearch {
    width: 90%;
    border: 1px solid #E0E4F1;
    border-radius: 12px;
    padding-left: 10px;
    height: 40px;
}

#containeButtonsAcceptOrCancel{
    width:35%;
}

#containerFilter {
    width:30%;
}

#buttonSeachFilter {
    margin-left: 15px;
    background-color: #2E5CFF;
    border: none;
    border-radius: 55px;
    width: 20%;
    height: 40px;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
}

.buttonActiveRQ {
    background-color: #2E5CFF;
    border: none;
    border-radius: 55px;
    width: 150px;
    height: 40px;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
    margin-left: 15px;
}

.buttonDesActiveRQ {
    background-color: #EFF2FD;
    border: none;
    border-radius: 55px;
    width: 150px;
    height: 40px;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #394055;
    margin-left: 15px;
}

.cardQuote {
    height: fit-content;
    background: #FFFFFF;
    border: 1.4px solid #F0EFFB;
    box-shadow: 0px 3px 10px rgba(17, 40, 121, 0.06);
    border-radius: 16px;
    padding: 16px 24px;
    margin: 2.5%;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

#consecutiveContainer-Review{
    background: #E6EBFF;
    border-radius: 8px;
    width: 30%;
    display: inline-block;
    padding: 6px 6px;
    vertical-align: middle;
    text-align: left;
}

#cardDecoration-Review {
    width: 50%;
    margin-left: 10%;
}

#consecutiveNumber-Review {
    font-weight: 600;
    font-size: 16px;
    color: #2E5CFF;
    text-align: left;
}

#cardDecorationArrow-Review {
    width: 40%;
    margin-left: 15px;
    background-color: #2E5CFF;
    color: white;
    vertical-align: middle;
    text-align: center;
}

#buttonRevertir {
    width: 60%;
    margin-left: 15px;
    background-color: red;
    color: white;
    vertical-align: middle;
    text-align: center;
}

/*********************************  CHANGE CURRENCIES  *****************************************/

#containerChangeCurrencies {
    width: 50%;
    min-height: 450px;
    margin-left: 25%;
    margin-top: 7.5%;
    background-color: #F9FAFF;
    border-radius: 15px;
    padding-top: 7%;
}

#div-Currency {
    margin-left: 10px;
    margin-right: 10px;
}

#p-Name-Supplier {
    width: 15%;
    padding-top: 10px;
}

.val-Usd {
    width: 20%;
    margin-left: 15px;
}

.button-Save-Active {
    width: 20%;
    margin-left: 40%!important;
    height: 40px;
    margin-left: 15px;
    background-color: #2E5CFF;
    color: white;
    margin-top: 15px;
    text-align: center;
    border: none;
    border-radius: 25px;
}

.button-Save-Desactive {
    width: 20%;
    margin-left: 40%;
    height: 40px;
    width: 15%;
    margin-top: 15px;
    background-color: #FFFFFF;
    color: black;
    border: 1px solid #F0EFFB;
    padding-left: 15px;
    border-radius: 25px;
}



/********************************* POPUP CHANGE PRICE *****************************************/

#container-Footer {
    width: 100%;
}

#tittleConversion {
    padding-top: 6px;
}

.buttonCurrency {
    background-color: #F7CB2F;
    color: #394055;
    border-radius: 55px;
    margin-left: 20px!important;
}

.buttonCurrency:focus, .buttonCurrency:hover {
    background-color: #FFEDAF;
    border: none;
}

/************************************ LISTA COTIZACIONES  ************************************************/
/************************************ LISTA COTIZACIONES  ************************************************/
/************************************ LISTA COTIZACIONES  ************************************************/

#titleQuotes {
    background: #F9FAFF;
    padding: 32px 12px 12px 48px;
    font-family: Hellix;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    height: 56px;
    display: flex;
    align-items: center;
}

#containerListQuotes {
    background: #F9FAFF;
    padding: 12px 36px 12px 36px;
    width: 100%;
    height: calc(100vh - 80px - 60px);
    overflow-x: hidden;
    overflow-y: auto;
}

#cardQuote {
    width: 360px;
    height: fit-content;
    background: #FFFFFF;
    border: 1.4px solid #F0EFFB;
    box-shadow: 0px 3px 10px rgba(17, 40, 121, 0.06);
    border-radius: 16px;
    padding: 16px 24px;
    margin: 12px;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

#labelAlias{
    font-family: Hellix;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
}

#labelContent{
    font-family: Hellix;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

#consecutiveContainer{
    background: #E6EBFF;
    border-radius: 8px;
    width: 98px;
    display: inline-block;
    padding: 6px 8px;
    vertical-align: middle;
    text-align: right;
}

#consecutiveNumber{
    font-weight: 600;
    font-size: 16px;
    color: #2E5CFF;
    width: "100%";
}

#cardDecoration {
    width: 34px;
    height: 34px;
    background: #394055;
    vertical-align: bottom;
    float: right;
    margin-right: 0px;
    border-radius: 50px;
    color: white;
    text-align: center;
    padding: 2px;
}

#cardDecorationArrow {
    color: white;
    vertical-align: middle;
    text-align: center;
}



/************************************  CATALOGO  ************************************************/

:root {
    --heigh-catalog: calc(100vh - 227px) !important;
  }

.catalog-head{
    width: 100vw;
    position: fixed;
    top:0;
}

.catalog-frame {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
}

.catalog-frame-root-middle{
    display: flex;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

.catalog-save-button{
    margin: 10px;
    width: 150px;
    align-self: center;
    background-color: #2E5CFF;
    color: white;
    border-radius: 54px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    border: none;
}

.catalog-save-button:hover{
    background-color: #2148d6;
}


.catalog-cancel-button {
    margin: 10px;
    width: 150px;
    align-self: center;
    background-color: #EFF2FD;
    color: #394055;
    border-radius: 54px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    border: none;
  }

  .catalog-cancel-button:hover{
    background-color: #d7dae5;
    color: #394055;
}

.catalog-modal-title{
    width: calc(100vw - 200px);
    font-weight: 600;
    font-size: 28px;
    align-self: center;
    padding-left: 40px;
}

.catalog-head-modal {
    display: flex;
    height: 50px;
    border: 1.2px solid #E0E4F1;
    padding: 3px;
    width: 100%;
}

.catalog-foot {
    position:fixed;
    bottom:0;
    height: 72px;
    border: 1.2px solid #E0E4F1;
    padding: 3px;
    width: 100%;
    background-color: white;
}

.catalog-frame-middle {
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    margin-bottom: 60px;
    padding-bottom: 12px;
}

.catalog-container-lists{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
}

.catalog-list-root {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    width: 85%;
    height: var(--heigh-catalog);
    overflow-x: hidden;
    overflow-y: auto;
}

#catalog-container-lists {
    background: #F9FAFF;
    padding: 0px 36px 0px 36px;
}

.catalog-list {
    display: flex;
    flex-wrap: wrap;
  }
.catalog-list>* {
    flex: 1 1 auto;
  }

.catalog-sku-description{
    font-family: Hellix;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    margin-top: 15px;
}

.catalog-cards-content{
    font-family: Hellix;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    flex: 1;
}

.catalog-card{  
    width: 320px;
    background: #FFFFFF;
    border: 1.4px solid #F0EFFB;
    box-shadow: 0px 3px 10px rgba(17, 40, 121, 0.06);
    border-radius: 16px;
    padding: 16px 24px;
    margin: 6px;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.catalog-sku-background{
    background: #E6EBFF;
    border-radius: 8px;
    width: 100%;
    display: inline-block;
    padding: 6px 8px;
    vertical-align: middle;
    text-align: right;
}

.catalog-sku-price{
    font-weight: 600;
    font-size: 16px;
    color: #F4493E;
    width: "100%";
    text-overflow: clip;
}

.catalog-sku-features{
    font-weight: 600;
    font-size: 16px;
    color: #1E5DFE;
    width: "100%";
    text-overflow: clip;
}

.catalog-container-search-box{
    display: flex;
    flex-direction: column;
    height: 74px;
    padding-left: 40px;
    padding-top: 13px;
    padding-bottom: 13px;
}

.catalog-root-search-box{
    width: 60vw;
}

.catalog-original-searched{
    background-color: #F7CB2F;
    width: 360px;
    height: 74px;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: 500;
    font-size: 16px;
    align-self:center;
    display: flex;
    align-items:center;
    justify-content:left;
    text-align: left;
}

.catalog-search-box{
    width: 95%;
    font-family: Hellix;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    padding: 8px 15px;
    border: 1.2px solid #E0E4F1;
    box-shadow: 0px 3px 10px rgba(17, 40, 121, 0.06);
    border-radius: 16px;
}

.ais-SearchBox-input:focus {
    outline-color: #f7cc2f95;
    outline-width: 1.5px;
    outline-offset: 0px;
  }

.catalog-load-previous {
   display: none;
}

.catalog-pagination-root {
    background-color: #f7cc2f95;
    color: #394055;
    font-weight: 500;
    font-size: 18px;
    border-radius: 14px;
    text-align: center;
    align-content: center;
}

.catalog-pagination {
    color: #394055;
    font-weight: 500;
    font-size: 16px;
    border-radius: 14px;
    padding: 6px 8px;
    width: fit-content;
    margin: 12px auto
}

.catalog-pagination-item{
    margin: 0px 4px;
}

.catalog-pagination-selected-item{
    font-weight: 600;
    font-size: 20px;
}

.catalog-reset-icon {
    width: 16px;
    height: 16px;
    margin-left: 10px;
    fill: #747B92;
}

.catalog-search-icon {
    display: none;
}

.catalog-filter-item{
    padding: 2px;
    font-weight: 500;
    font-size: 14px;
}

.catalog-filter-checkbox {
    margin-right: 8px;
    width: 18px;
    height: 18px;
}

.catalog-filter{
    width: 290px;
    height: var(--heigh-catalog);
    padding-right: 12px;
    overflow-x: hidden;
    overflow-y: auto;
}

.ais-RefinementList-searchBox .ais-SearchBox-input {
    margin-bottom: 12px;
    margin-top: 10px;
    padding: 5px 12px;
    border: 1.2px solid #E0E4F1;
    border-radius: 10px; 
}

.ais-SearchBox-submit {
    display: none; 
}

.ais-RefinementList-searchBox .ais-SearchBox-reset {
        display: none; 
}

.buttonSupplierCatalog {
    background-color: #E6EBFF;
    width: 100%;
    min-height: 45px;
}

#buttonNotStock {
    background-color: rgba(255,0,0,.5);
    width: 100%;
    color: black;
}


/************************************  CATALOGO EDIT SEARCH QUOTE  ************************************************/

.catalog-edit-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(var(--heigh-catalog));
    width: 360px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #2E5CFF;
}

/************************************  CATALOGO EDIT MODAL  ************************************************/


.catalog-modal-frame {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100vw;
    height: 100vh;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    padding-right: 15px;

}
  
.catalog-modal-centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-x: hidden;
    overflow-y: hidden;
}
  
.catalog-modal {
    width: 90vw;
    height: 100vh;
    background-color: white;
    z-index: 2;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}


#modal-catalog {
    display: block;
    background-color: rgba(0, 0, 0, .8);
}



#tittlePopUpPublished {
    font-weight: 700;
    font-size: 20px;
    color: #394055;
    padding-top: 5px;
    height: 50px;
}

/********************************* PopUpTech *********************************/
#buttonCloseTechFile {
    color: black!important;
    font-size: 30px;
    margin-left: 95%;
}

#containerModal {
    z-index: 100;
}
#modalStyle {
    
    z-index: 1;
}

.carousel-item {
    height: 100px;
}

.negroGrueso {
    color: #394055;
    font-weight: 700;
}

.colorAzul {
    color:#1E5DFE
}

#dowloandTechFile {
    background-color: #1E5DFE;
    border-radius: 75px;
    color: white;
    border: none;
    height: 40px;
    width: 150px;
    font-size: 16px;
}

#headerTechFile {
    height: 50px;
}

.slider-container {
    display: flex;
    width: 100%;
    height: 300px;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }
  
  .slider-container img {
    flex: 0 0 100%;
    width: 100%;
    object-fit: cover;
    scroll-snap-align: center;
  }

  /*************************** User Page **********************************/
  #containerBottonsUsers {
    height: 60px;
  }

  .buttonUsersWhite {
    height: 40px;
    background-color: #FFFFFF;
    border: 2px solid #EFF2FD;
    border-radius: 55px;
    color: #394055;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    padding-left: 15PX;
    padding-right: 15PX;
}

.buttonUsersBlue {
    height: 40px;
    background-color: #1E5DFE;
    border: 2px solid #EFF2FD;
    border-radius: 55px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    padding-left: 15PX;
    padding-right: 15PX;
}

#inputUserFilter {
    background: #FFFFFF;
    height: 35px;
    border: 1px solid #E0E4F1;
    border-radius: 12px;
}

/*********************************  POPUP NEW CLIENT ****************************/

.modalDialogNewUser {
    max-width: 450px;
}

.modalContentNewUser {
    width: 450px;
    min-height: 500px;
    padding: 10px 20px;
}

.tittleNewClient {
    color: #394055;
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 0;
}

.headerNewClient {
    border-bottom: none;
    padding-bottom: 0;
}

#tittleBody {
    font-size: 14px;
    font-weight: 600;
    color: #2763FC;
}

.inputNewUser {
    width: 100%;
    border: none;
    border-bottom: 1px solid #394055;
    height: 40PX;
    padding-left: 10px;
}

.bodyNewUser {
    border-bottom: none;
}

.footerNewUser{
    border-top: none;
}

#buttonCanPublish {
    color: white;
    background-color: #2763FC;
    width: 130px;
    height: 60px;
    border-style: none;
    border-radius: 6px;
}

#buttonCanPublishDisabled {
    color: white;
    background-color: #8ba8f0;
    width: 130px;
    height: 60px;
    border-style: none;
    border-radius: 6px;
}

#buttonCancel {
    color: #2763FC;
    background-color: white;
    border-style: none;
}

/**************************************  Review Client *****************************************/
#containerTopReviewClient {
    height: 110px;
}
#tittleClientReviewClient {
    color: #394055;
    font-size: 36px;
    font-weight: 600;
}
.buttonBlueReviewClient {
    background-color: #2763FC;
    color: white;
    height: 5vh;
    width: 120px;
    border-style: none;
    border-radius: 10px;
}
#containerButtonsTopReviewClient {
    margin-right: 10%;
}

#containerBodyReviewClient {
    background-color: #F9FAFF;
    min-height: 75vh;
}

.containerLeftReviewClient {
    margin-left: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
}

#tittleNumberClient {
    font-size: 22px;
    color: #394055;
    font-weight: 600;
    margin-bottom: 0;
}

.tittleIdClient {
    font-size: 16px;
    font-weight: 400;
    color: #394055;
    margin-bottom: 0;
}

.tittleUpdateReviewClient {
    color: #2763FC;
    font-size: 16px;
    font-weight: 400;
}

.secondPartLeftReviewClient {
    background-color: white;
    min-height: 500px;
    width: 95%;
}

.containerRigthReviewClient {
    background-color: white;
    margin: 30px;
    padding-left: 40px;
    padding-right: 40px;
    height: 200px;
}

#buttonSelectCategory {
    height: 40px;
}

.tittlePartBlue {
    color: #2763FC;
    font-size: 14px;
    font-weight: 600;
}

.inputReviewClient {
    width: 100%;
    border: none;
    border-bottom: 1px solid #394055;
    height: 40PX;
    padding-left: 10px;
}

.paragraphDataUserCompany {
    margin-bottom: 0;
}

.containerInfoAnexos {
    background-color: #F4F6F8;
    border: 1px #BDC3D8 solid;
    padding: 15px;
}

/************************************ filter client *******************/

.headerSearchClientMain {
    border-style: none;
}

.containerSearchClient {
    width: 100%;
    min-height: 500px;
}

.headerSearchClient {
    width: 100%;
    height: 60px;
    background-color: #394055;
}

.tittleSearchClient {
    color: white;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    padding-top: 7px;
}

.buttonCloseSearch {
    width: 80px;
    height: 40px;
    color: white;
    background-color: transparent;
    border: 1px solid #FFFFFF;
    border-radius: 90px;
    font-size: 14px;
    margin-left: 40%;
}

.containerInputSearch {
    width: 100%;
    height: 80px;
}

.inputSearchClient{
    width: 50%;
    height: 50px;
    background-color: #EFF2FD;
    border-radius: 16px;
    border-style: none;
    margin-left: 25%;
    padding-left: 6%;
}

.containerInputSearch .iconInInput {
    position: absolute;
    margin-left: 27%;
}

.containerTagsOfClients {
    height: 400px;
    background-color: #EFF2FD;
    border: none;
}

.containerFooterOfClients {
    height: 80px;
}

.buttonCreateNewClientSearchClient {
    background-color: #1E5DFE;
    color: white;
    width: 300px;
    height: 50px;
    border-style: none;
    border-radius: 200px;
    font-size: 16px;
}

.tagUser {
    height: 100px;
    width: 90%;
    margin-left: 5%;
    background-color: white;
    margin-bottom: 15px;
}

.modalSearchClient {
    min-width: 60%!important;
}

.textBoldBlack {
    font-size: 16px;
    color: #394055;
    font-weight: 700;
}

/*********************************  ORDER PAGE *****************************************************/
#buttonReturnHome {
    height: 40px;
    background-color: #FFFFFF;
    border: 2px solid #EFF2FD;
    border-radius: 55px;
    color: #394055;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    padding-left: 15PX;
    padding-right: 15PX;
}

#inputSearchOrder {
    border: none;
    border-radius: 55px;
    padding: 5px;
    padding-left: 15px;
    border: 1px solid #EFF2FD;
    width: 33%;
}

#consecutiveContainer-Review-Order {
    background: #E6EBFF;
    border-radius: 8px;
    width: 40%;
    display: inline-block;
    padding: 6px 6px;
    vertical-align: middle;
    text-align: left;
    margin-left: 15px;
    text-align: center;
}

#cardDecorationArrow-Review-Blue {
    width: 40%;
    margin-left: 15px;
    background-color: #2E5CFF;
    color: white;
    vertical-align: middle;
    text-align: center;
}

#cardDecorationArrow-Review-Red {
    width: 40%;
    margin-left: 15px;
    background-color: red;
    color: white;
    vertical-align: middle;
    text-align: center;
}

#cardDecorationArrow-Review-Green {
    width: 40%;
    margin-left: 15px;
    background-color: green;
    color: white;
    vertical-align: middle;
    text-align: center;
}

#cardDecorationArrow-Review-Black {
    width: 40%;
    margin-left: 15px;
    background-color: #2E3344;
    color: white;
    vertical-align: middle;
    text-align: center;
}

#cardDecorationArrow-Review-Yellow {
    width: 40%;
    margin-left: 15px;
    background-color: #F7CB2F;
    color: #2E3344;
    vertical-align: middle;
    text-align: center;
}

#cardDecorationArrow-Review-LightBlue {
    width: 40%;
    margin-left: 15px;
    background-color: #E4E9FC;
    color: #2E3344;
    vertical-align: middle;
    text-align: center;
    border: none;
}

.buttonListOfSkus{
    background-color: #2E5CFF;
    width: 80%;
    margin-left: 10%;
    margin-top: 10px;
    color: white;
    height: 40px;
    border-style: none;
    border-radius: 10px;
}

/**********************************  PopUp ConfirmPayment **************************************/
#tittlePopUpConfirm {
    font-size: 35px;
    font-weight: 600;
}

.buttonTypePayment {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 60px;
    background-color: #2E5CFF;
    color: white;
    vertical-align: middle;
    text-align: center;
}

#containerButtonsTypePay {
    width: 40%;
    margin-left: 30%;
}

/**********************************  POPUP ADD MANUAL DATA ***************************************/
#inputData {
    width: 90%;
    margin-left: 5%;
    border-radius: 15px;
    height: 450px;
    padding: 20px;
}

#buttonAddData {
    border: none;
    background-color: #2E5CFF;
    color: white;
    margin-top: 30px;
    width: 20%;
    padding: 10px;
    border-radius: 50px;
    margin-left: 40%;
}

/********************************  POP UP STATS VENTAS *******************************************/
.tittleStatsPart {
    font-size: 25px;
    font-weight: 700;
}

.subTittleStatsPart {
    font-size: 18px;
    font-weight: 700;
}

#inputDate {
    width: 80%;
    margin-left: 10%;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 5px 10px;
}

.stylebuttonStats {
    width: 120px;
    height: 40px;
    background-color: #2E5CFF;
    border: 1.2px solid #E0E4F1;
    border-radius: 55px;
    font-family: Hellix;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: white;
}

.modal-dialog-client {
    max-width: 550px;
}

.modal-content-client {
    width: 550px;
    min-height: 500px;
    padding: 10px 20px;
}

.title-modal-stats {
    padding-top: 2px;
}

.button-modal-stats {
    width: 100px;
    height: 35px;
    background-color: #2E5CFF;
    border: 1.2px solid #E0E4F1;
    border-radius: 55px;
    font-family: Hellix;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: white;
}

.timeline-modal {
    flex-direction: row;
}

.timeline-modal .buttons-shortcuts button {
    background: #ffff;
    border: 1px solid #2E3344;
    border-radius: 4px;
    font-weight: bold;
    padding: 5px 10px;
}



.timeline-modal .buttons-shortcuts:first-child button:enabled {
    background: #2E3344;
    border: 1px solid #2E3344;
    border-radius: 3px;
    color: #fff;
    font-weight: bold;
}

.timeline-modal .buttons-shortcuts:nth-child(2) {
    margin-left: 10px;
}

.date-modal-stats {
    width: 50%;
    margin-left: 10px;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 5.5px 10px;
}

.data-modal {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
}

.components-modal {
    flex-direction: column;
    padding: 10px 0px 5px 0px;
}

.info-component-modal {
    display: flex;
    flex-direction: row;
}

.header-component-modal > p{
    font-size: 25px;
    font-weight: 800;
    padding: 0;
    margin: 0;
}
.header-arrow-false {
    padding: 9px;
    color: red;
    font-weight: bold;
}

.header-arrow-true {
    padding: 9px;
    color: blue;
    font-weight: bold;
}

.arrow-true {
    color: blue;
    padding: 0px 0px 0px 5px;
}

.arrow-false {
    color: red;
    padding: 0px 0px 0px 5px;
}

.users-component-modal > p{
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}
/**********************************  GENERAL  ************************************************************/
.width150 {
    width: 150px;
}

.width5 {
    width: 5%;
}

.width10 {
    width: 10%;
}

.width15 {
    width: 15%;
}

.width20 {
    width: 20% !important;
}

.width23 {
    width: 23%;
}

.width33 {
    width: 33%;
}

.width40 {
    width: 40%;
}

.width48 {
    width: 48%;
}

.width50 {
    width: 50%;
}

.width60 {
    width: 60% !important;
}

.width73 {
    width: 73%;
}

.width80 {
    width: 80%;
}

.width85 {
    width: 85%;
}

.width90 {
    width: 90%;
}

.width95 {
    width: 95%;
}

.width98 {
    width: 98%;
}

.width100 {
    width: 100% !important;
}

.height100 {
    height: 180px;
}

.marginL5 {
    margin-left: 5%;
}

.marginL15 {
    margin-left: 15px;
}

.marginL20 {
    margin-left: 20%;
}

.marginL10 {
    margin-left: 10%;
}

.marginT50 {
    margin-top: 50%;
}

.width49 {
    width: 49%;
}

.textUpper {
    text-transform: uppercase;
}

.textNormal {
    font-size: 16px;
    font-weight: 500;
}

.colorBlue {
    color: #2763FC;
}

.colorBlackLitgh {
    color: #394055;
}

.minHeight80 {
    min-height: 80px;
}

.borderForm {
    border: 1px solid #ced4da;
    border-radius: .375rem;
}